import * as React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { useForm } from '../../Common/Hooks/UseForm';

const ContactForm = () => {

    const { values } = useForm(submitHandler, {
        name: '',
        phone: '',
        email: '',
        message: '',
    });
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const contactFormSchema = yup.object().shape({
        name: yup.string().required('Please Enter a name'),
        email: yup.string().email('Please Enter a valid Email').required('Please Enter an Email'),
        phone: yup.string().required('Please Enter a phone number').matches(phoneRegExp, 'Phone number is not valid'),
        message: yup.string().required('Write a Message'),
    });

    function submitHandler() {
        console.log('submit');
    }

    return (
        <Formik initialValues={values}
            validationSchema={contactFormSchema}
            onSubmit={values => {
                console.log(values);
            }}>
            {({ errors, touched, values, handleChange }) => (
                <Form>
                    <div className="mb-3">
                        <label htmlFor="txt-name" className="form-label">
                            Name <span className="red">*</span>
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                            name="name"
                            id="txt-name"
                            placeholder="Put your name here"
                            value={values.name}
                            onChange={handleChange}
                        />
                        {errors.name &&
                            touched.name &&
                            <span className="input-feedback">
                                {errors.name}
                            </span>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="txt-phone" className="form-label">
                            Phone Number <span className="red">*</span>
                        </label>
                        <NumberFormat
                            prefix="+"
                            className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                            name="phone"
                            id="txt-phone"
                            placeholder="Put your phone number here"
                            value={values.phone}
                            onChange={handleChange}
                            allowNegative={false}
                        />
                        {errors.phone &&
                            touched.phone &&
                            <span className="input-feedback">
                                {errors.phone}
                            </span>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="txt-email" className="form-label">
                            Email <span className="red">*</span>
                        </label>
                        <input
                            type="email"
                            className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                            id="txt-email"
                            name="email"
                            placeholder="Put your email here"
                            onChange={handleChange}
                            value={values.email}
                        />
                        {errors.email &&
                            touched.email &&
                            <span className="input-feedback">
                                {errors.email}
                            </span>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="txt-message" className="form-label">
                            Message <span className="red">*</span>{" "}
                        </label>
                        <textarea
                            className={`form-control ${errors.message && touched.message ? 'is-invalid' : ''}`}
                            id="txt-message"
                            name="message"
                            rows={3}
                            value={values.message}
                            placeholder="Put your message here"
                            onChange={handleChange}
                        ></textarea>
                        {errors.message &&
                            touched.message &&
                            <span className="input-feedback">
                                {errors.message}
                            </span>}
                    </div>
                    <div className="mb-3 align-right">
                        <button type="submit" className="btn btn-primary">
                            SEND MESSAGE
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;
